<template>
    <div class="page-table">
        <a-radio-group :default-value="activeTab" v-model="activeTab">
            <a-radio-button :value="1" :disabled="isYKP == 2">
                销售单
            </a-radio-button>
            <a-radio-button :value="2" :disabled="$route.query.status == 1 || isYKP == 1">
                预开票
            </a-radio-button>
        </a-radio-group>
        <a-tabs :default-active-key="invoicingType" @change="callback">
            <a-tab-pane v-if="activeTab == 1" :key="1">
                <span slot="tab">
                    销售单
                    <span class="tips-rad">{{ invoiceNumber1 }}</span>
                    <a-tooltip>
                        <template slot="title">
                            <span>由客户经理在CRM中自行提交的申请</span>
                        </template>
                        <a-icon type="question-circle" />
                    </a-tooltip>
                </span>
                <div>
                    <a-table :columns="columns" :dataSource="dataSource" bordered :loading="tabLoading"
                        :pagination="false" :rowKey="(record, index) => index" :scroll="{ x: 1500 }"
                        class="components-table-demo-nested">
                        <template slot="submitTime" slot-scope="text">
                            <span v-if="text">{{ formatDateMin(text) }}</span>
                            <span v-else>-</span>
                        </template>
                        <template slot="expectedProcurementTime" slot-scope="text">
                            <span v-if="text">{{ formatDate(text) }}</span>
                            <span v-else>-</span>
                        </template>
                        <template slot="createTime" slot-scope="text">
                            <span v-if="text">{{ formatDateMin(text) }}</span>
                            <span v-else>-</span>
                        </template>
                        <template slot="money" slot-scope="record,text">
                            <span>
                                <a-input v-rule-number :value="text.money" @blur="e => editAmount(e, text)"></a-input>
                            </span>
                        </template>
                        <template slot="name" slot-scope="text">
                            <a-popover>
                                <template slot="content">
                                    {{ text }}
                                </template>
                                {{ text }}
                            </a-popover>
                        </template>
                        <template slot="customerName" slot-scope="text">
                            <a-popover>
                                <template slot="content">
                                    {{ text }}
                                </template>
                                {{ text }}
                            </a-popover>
                        </template>
                    </a-table>
                    <div class="page-box">
                        <a-pagination :total="total" :current="queryData.page + 1" :pageSize="queryData.size"
                            @showSizeChange="onShowSizeChange" show-size-changer :page-size-options="pageSizeOptions"
                            @change="handlePageChange" />
                    </div>
                </div>
            </a-tab-pane>
            <a-tab-pane v-if="activeTab == 1" :key="4" force-render>
                <span slot="tab">
                    货堆
                    <span class="tips-rad">{{ invoiceNumber4 }}</span>
                    <a-tooltip>
                        <template slot="title">
                            <span>如未查询到记录请与采购部确认是否已上传至OMS</span>
                        </template>
                        <a-icon type="question-circle" />
                    </a-tooltip>
                </span>
                <div>
                    <a-table :columns="pileColumns" :dataSource="pileDataSource" bordered :loading="pileLoading"
                        :pagination="false" :rowKey="(record, index) => index" :scroll="{ x: 1500 }"
                        class="components-table-demo-nested">
                        <!-- :row-selection="rowSelection2" -->
                        <template slot="orderTime" slot-scope="text">
                            <span v-if="text">{{ formatDateMin(text) }}</span>
                            <span v-else>-</span>
                        </template>
                        <template slot="money" slot-scope="record,text">
                            <span>
                                <a-input v-rule-number :value="text.money" @blur="e => editAmount(e, text)"></a-input>
                            </span>
                        </template>
                        <template slot="invoice" slot-scope="text">
                            <span v-if="text == 1">可开票</span>
                            <span v-if="text == 2">不可开票</span>
                            <span v-if="text == 0">未知</span>
                        </template>

                        <template slot="amount" slot-scope="text">
                            <span v-if="text">{{ text.toFixed(2) }}</span>
                            <span v-else>0</span>
                        </template>
                        <template slot="surplusAmount" slot-scope="text">
                            <span v-if="text">{{ text.toFixed(2) }}</span>
                            <span v-else>0</span>
                        </template>
                        <template slot="createTime" slot-scope="text">
                            <span v-if="text">{{ formatDateMin(text) }}</span>
                            <span v-else>-</span>
                        </template>
                    </a-table>

                    <div class="page-box">
                        <a-pagination :total="pileTotal" :current="pileParams.page + 1" :pageSize="pileParams.size"
                            @showSizeChange="onShowSizeChange" show-size-changer :page-size-options="pageSizeOptions"
                            @change="handlePageChange1" />
                    </div>
                </div>
            </a-tab-pane>
            <a-tab-pane v-if="activeTab == 1" :key="5" force-render>
                <span slot="tab">
                    宅配
                    <span class="tips-rad">{{ invoiceNumber5 }}</span>
                    <a-tooltip>
                        <template slot="title">
                            <span>如未查询到记录请与采购部确认是否已上传至OMS</span>
                        </template>
                        <a-icon type="question-circle" />
                    </a-tooltip>
                </span>
                <div>
                    <a-table :columns="resColumns" :dataSource="resDataSource" bordered :loading="resLoading"
                        :pagination="false" :rowKey="(record, index) => index" :scroll="{ x: 1500 }"
                        class="components-table-demo-nested">
                        <template slot="submitTime" slot-scope="text">
                            <span v-if="text">{{ formatDateMin(text) }}</span>
                            <span v-else>-</span>
                        </template>
                        <template slot="amount" slot-scope="text">
                            <span v-if="text">{{ text.toFixed(2) }}</span>
                            <span v-else>0</span>
                        </template>
                        <template slot="money" slot-scope="record,text">
                            <span>
                                <a-input v-rule-number :value="text.money" @blur="e => editAmount(e, text)"></a-input>
                            </span>
                        </template>
                        <template slot="surplusAmount" slot-scope="text">
                            <span v-if="text">{{ text.toFixed(2) }}</span>
                            <span v-else>0</span>
                        </template>
                        <template slot="expectedProcurementTime" slot-scope="text">
                            <span v-if="text">{{ formatDate(text) }}</span>
                            <span v-else>-</span>
                        </template>
                        <template slot="createTime" slot-scope="text">
                            <span v-if="text">{{ formatDateMin(text) }}</span>
                            <span v-else>-</span>
                        </template>

                        <template slot="outCardOxp" slot-scope="text">
                            <span v-if="text">{{ text.amount }}</span>
                            <span v-else>-</span>
                        </template>

                        <template slot="name" slot-scope="text">
                            <a-popover>
                                <template slot="content">
                                    {{ text }}
                                </template>
                                {{ text }}
                            </a-popover>
                        </template>
                        <template slot="invoice" slot-scope="text">
                            <span v-if="text == 1">可开票</span>
                            <span v-if="text == 2">不可开票</span>
                            <span v-if="text == 0">未知</span>
                        </template>
                        <template slot="customerName" slot-scope="text">
                            <a-popover>
                                <template slot="content">
                                    {{ text }}
                                </template>
                                {{ text }}
                            </a-popover>
                        </template>
                    </a-table>
                    <div class="page-box">
                        <a-pagination :total="resTotal" :current="resParams.page + 1" :pageSize="resParams.size"
                            @showSizeChange="onShowSizeChange" show-size-changer :page-size-options="pageSizeOptions"
                            @change="handlePageChange2" />
                    </div>
                </div>
            </a-tab-pane>

            <a-tab-pane v-if="activeTab == 1" :key="6" force-render>
                <span slot="tab">
                    线下活动
                    <span class="tips-rad">{{ invoiceNumber6 }}</span>
                    <a-tooltip>
                        <template slot="title">
                            <span>如未查询到记录请与采购部确认是否已上传至OMS</span>
                        </template>
                        <a-icon type="question-circle" />
                    </a-tooltip>
                </span>
                <div>
                    <!-- :row-selection="rowSelection4" -->
                    <a-table :columns="resColumns" :dataSource="offDataSource" bordered :loading="resLoading"
                        :pagination="false" :rowKey="(record, index) => index" :scroll="{ x: 1500 }"
                        class="components-table-demo-nested">
                        <template slot="submitTime" slot-scope="text">
                            <span v-if="text">{{ formatDateMin(text) }}</span>
                            <span v-else>-</span>
                        </template>
                        <template slot="money" slot-scope="record,text">
                            <span>
                                <a-input v-rule-number :value="text.money" @blur="e => editAmount(e, text)"></a-input>
                            </span>
                        </template>
                        <template slot="expectedProcurementTime" slot-scope="text">
                            <span v-if="text">{{ formatDate(text) }}</span>
                            <span v-else>-</span>
                        </template>
                        <template slot="createTime" slot-scope="text">
                            <span v-if="text">{{ formatDateMin(text) }}</span>
                            <span v-else>-</span>
                        </template>
                        <template slot="surplusAmount" slot-scope="text">
                            <span v-if="text">{{ text }}</span>
                            <span v-else>0</span>
                        </template>

                        <template slot="amount" slot-scope="text">
                            <span v-if="text">{{ text.toFixed(2) }}</span>
                            <span v-else>0</span>
                        </template>

                        <template slot="outCardOxp" slot-scope="text">
                            <span v-if="text">{{ text.amount }}</span>
                            <span v-else>-</span>
                        </template>

                        <template slot="name" slot-scope="text">
                            <a-popover>
                                <template slot="content">
                                    {{ text }}
                                </template>
                                {{ text }}
                            </a-popover>
                        </template>
                        <template slot="invoice" slot-scope="text">
                            <span v-if="text == 1">可开票</span>
                            <span v-if="text == 2">不可开票</span>
                            <span v-if="text == 0">未知</span>
                        </template>
                        <template slot="customerName" slot-scope="text">
                            <a-popover>
                                <template slot="content">
                                    {{ text }}
                                </template>
                                {{ text }}
                            </a-popover>
                        </template>
                    </a-table>
                    <div class="page-box">
                        <a-pagination :total="offTotal" :current="offParams.page + 1" :pageSize="offParams.size"
                            @showSizeChange="onShowSizeChange" show-size-changer :page-size-options="pageSizeOptions"
                            @change="handlePageChange3" />
                    </div>
                </div>
            </a-tab-pane>
            <a-tab-pane :key="3" tab="预开票" v-if="activeTab == 2">
                <a-alert message="客户需要先开具发票后才能采购卡券、货堆、宅配等，则可选择预开票栏目，客户采购完成请记录关联相关销售单避免造成欠票" banner />
                <p style="margin-top:10px;">
                    <span style="color:#000;font-weight: 700;margin-right:20px">开票金额:</span>
                    <a-input v-rule-number v-model="totalPrice" style="width:200px;"></a-input>
                </p>
            </a-tab-pane>
        </a-tabs>

        <div style="display:flex;justify-content: center;margin-top:20px;">
            <a-button type="primary" @click="save">保存</a-button>
        </div>
    </div>
</template>
<script>
import { request } from "@/utils/request";
import { pileColumns, resColumns } from '../components/Columns.js'
export default {
    props: {
        customerId: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            columns: [
                { title: '序号', customRender: (text, record, index) => index + 1, align: 'center', width: 80, },
                {
                    title: "创建时间",
                    dataIndex: "createTime",
                    scopedSlots: { customRender: "createTime" },
                    customRender: (value, row, index) => {
                        value = this.formatDateMin(value);
                        const obj = {
                            children: value,
                            attrs: {},
                        };
                        let result = this.findDuplicateIds(this.dataSource);
                        result.forEach((item) => {
                            if (item.id == row.id) {
                                obj.attrs.rowSpan = 0;
                                if (index == item.indexes[0]) {
                                    obj.attrs.rowSpan = item.indexes.length;
                                }
                            }
                        });
                        return obj;
                    },
                    align: "center",
                    width: 150,
                },
                {
                    title: "提交时间",
                    dataIndex: "submitTime",
                    customRender: (value, row, index) => {
                        if (value) {
                            value = this.formatDateMin(value);
                        } else {
                            value = "-";
                        }
                        const obj = {
                            children: value,
                            attrs: {},
                        };
                        let result = this.findDuplicateIds(this.dataSource);
                        result.forEach((item) => {
                            if (item.id == row.id) {
                                obj.attrs.rowSpan = 0;
                                if (index == item.indexes[0]) {
                                    obj.attrs.rowSpan = item.indexes.length;
                                }
                            }
                        });
                        return obj;
                    },
                    align: "center",
                    width: 150,
                },
                {
                    title: "客户名称",
                    dataIndex: "customerName",
                    customRender: (value, row, index) => {
                        const obj = {
                            children: value,
                            attrs: {},
                        };
                        let result = this.findDuplicateIds(this.dataSource);
                        result.forEach((item) => {
                            if (item.id == row.id) {
                                obj.attrs.rowSpan = 0;
                                if (index == item.indexes[0]) {
                                    obj.attrs.rowSpan = item.indexes.length;
                                }
                            }
                        });
                        return obj;
                    },
                    // ellipsis: true,
                    align: "center",
                    width: 150,
                },
                {
                    title: "可开票金额",
                    dataIndex: "surplusAmount",
                    customRender: (value, row, index) => {
                        // if(value) {
                        //     value = value.toFixed(2)
                        // }
                        value = value.toFixed(2)
                        console.log(value, 'value')
                        const obj = {
                            children: value,
                            attrs: {},
                        };
                        let result = this.findDuplicateIds(this.dataSource);
                        result.forEach((item) => {
                            if (item.id == row.id) {
                                obj.attrs.rowSpan = 0;
                                if (index == item.indexes[0]) {
                                    obj.attrs.rowSpan = item.indexes.length;
                                }
                            }
                        });
                        return obj;
                    },
                    align: "center",
                    ellipsis: true,
                    width: 150,
                },
                {
                    title: "开票金额",
                    dataIndex: "money",
                    align: "center",
                    scopedSlots: { customRender: "money" },
                    width: 150,
                },
                // {
                //     title: "已开票金额",
                //     dataIndex: "alreadyAmount",
                //     customRender: (value, row, index) => {
                //         const obj = {
                //             children: value,
                //             attrs: {},
                //         };
                //         let result = this.findDuplicateIds(this.dataSource);
                //         result.forEach((item) => {
                //             if (item.id == row.id) {
                //                 obj.attrs.rowSpan = 0;
                //                 if (index == item.indexes[0]) {
                //                     obj.attrs.rowSpan = item.indexes.length;
                //                 }
                //             }
                //         });
                //         return obj;
                //     },
                //     align: "center",
                //     width: 150,
                // },
                {
                    title: "实收金额",
                    dataIndex: "amount",
                    customRender: (value, row, index) => {
                        if (value) {
                            value = value.toFixed(2)
                        }
                        const obj = {
                            children: value,
                            attrs: {},
                        };
                        let result = this.findDuplicateIds(this.dataSource);
                        result.forEach((item) => {
                            if (item.id == row.id) {
                                obj.attrs.rowSpan = 0;
                                if (index == item.indexes[0]) {
                                    obj.attrs.rowSpan = item.indexes.length;
                                }
                            }
                        });
                        return obj;
                    },
                    align: "center",
                    ellipsis: true,
                    width: 150,
                },
                // {
                //     title: "开票状态",
                //     dataIndex: "invoiceAmount",
                //     customRender: (value, row, index) => {
                //         const obj = {
                //             children: value,
                //             attrs: {},
                //         };
                //         let result = this.findDuplicateIds(this.dataSource);
                //         result.forEach((item) => {
                //             if (item.id == row.id) {
                //                 obj.attrs.rowSpan = 0;
                //                 if (index == item.indexes[0]) {
                //                     obj.attrs.rowSpan = item.indexes.length;
                //                 }
                //             }
                //         });
                //         return obj;
                //     },
                //     align: "center",
                //     ellipsis: true,
                //     width: 150,
                // },
                {
                    title: "客户经理",
                    dataIndex: "staffName",
                    // customRender: renderContent,
                    customRender: (value, row, index) => {
                        const obj = {
                            children: value,
                            attrs: {},
                        };
                        let result = this.findDuplicateIds(this.dataSource);
                        result.forEach((item) => {
                            if (item.id == row.id) {
                                obj.attrs.rowSpan = 0;
                                if (index == item.indexes[0]) {
                                    obj.attrs.rowSpan = item.indexes.length;
                                }
                            }
                        });
                        return obj;
                    },
                    align: "center",
                    width: 150,
                },
                {
                    title: "销售订单号",
                    dataIndex: "code",
                    customRender: (value, row, index) => {
                        const obj = {
                            children: value,
                            attrs: {},
                        };
                        let result = this.findDuplicateIds(this.dataSource);
                        result.forEach((item) => {
                            if (item.id == row.id) {
                                obj.attrs.rowSpan = 0;
                                if (index == item.indexes[0]) {
                                    obj.attrs.rowSpan = item.indexes.length;
                                }
                            }
                        });
                        return obj;
                    },
                    align: "center",
                    width: 220,
                },
                {
                    title: "出库批次号",
                    dataIndex: "outBatchCode",
                    align: "center",
                    width: 220,
                },

            ],
            invoicingType: 1,
            pileColumns,
            resColumns,
            pileDataSource: [],//货堆数据
            pileLoading: false,//货堆loading
            customerData: [],//客户列表
            invoiceList: [],//发票
            dataSource: [],//销售单列表
            resDataSource: [],//宅配列表
            offDataSource: [],//线下活动
            queryData: {
                page: 0,
                size: 10,
                customerId: this.customerId
                // f5b8a7ae-2bf6-4265-bb73-817ad6ebc17b
            },
            selectedRowsList: [],
            selectedRowsList1: [],
            selectedRowsList2: [],
            selectedRowsList3: [],
            selectedRowsList4: [],
            selectedRowsKeys1: [],
            selectedRowsKeys2: [],
            selectedRowsKeys3: [],
            selectedRowsKeys4: [],
            selectRowsObj1: {},
            selectRowsObj2: {},
            selectRowsObj3: {},
            selectRowsObj4: {},
            sellerList: [],//主体公司
            // 列表总页数
            total: 0,
            pileTotal: 0,
            resTotal: 0,
            offTotal: 0,
            // 列表加载中
            offLoading: false,
            tabLoading: false,
            tabOutLoading: false,
            resLoading: false,
            // 货堆列表参数
            pileParams: {
                page: 0,
                size: 10,
                type: 3,
                customerId: ""
            },
            // 宅配列表参数
            resParams: {
                page: 0,
                size: 10,
                type: 4,
                customerId: ""
            },
            offParams: {
                page: 0,
                size: 10,
            },


            // 开票内容data
            selectData: [],
            totalPrice: undefined,

            // 关联最大金额
            GlAmount: 0,
            activeTab: 1,//1销售单 2预开票
            isYKP: 0,//是否预开票
            pageSizeOptions: ['10', '30', '40', '50', '100', '500', '1000'],
            invoiceNumber1: 0,
            invoiceNumber4: 0,
            invoiceNumber5: 0,
            invoiceNumber6: 0,
        }
    },
    mounted() {
        this.getDetails();
    },

    watch: {
        // 监听 message 变化
        customerId(newValue) {
            if (newValue) {
                this.queryData.customerId = newValue;
            }
        }
    },
    methods: {
        onShowSizeChange(current, pageSize) {
            if (this.invoicingType == 1) {
                this.queryData.size = pageSize;
                this.getList()
            }
            if (this.invoicingType == 4) {
                this.pileParams.size = pageSize;
                this.getPileList();//获取货堆列表
            }
            if (this.invoicingType == 5) {
                this.resParams.size = pageSize;
                this.getResList();//获取宅配列表
            }
            if (this.invoicingType == 6) {
                this.offParams.size = pageSize;
                this.getOfflineList();//获取线下活动列表
            }
        },
        handleAmountValidation(dataSource, invoicingType, text, val) {
            // 强制转换为数值并保留两位小数
            const numericVal = parseFloat(val) || 0;
            const numericSurplus = parseFloat(text.surplusAmount) || 0;

            // 查找目标数据项
            const index = dataSource.findIndex(i =>
                (invoicingType === 1 ? i.id === text.id : i.code === text.code)
            );

            // 精确比较（放大100倍取整避免浮点误差）
            if (Math.round(numericVal * 100) > Math.round(numericSurplus * 100)) {
                this.$message.error('开票金额不能大于可开票金额，请重新输入');
                this.$set(dataSource[index], 'money', ''); // 清空非法输入
                return false;
            }

            // 存储规范化的两位小数金额
            this.$set(dataSource[index], 'money', numericVal.toFixed(2));
            return true;
        },
        // 输入开票金额
        editAmount(e, text) {
            const val = e.target.value;

            // 统一处理逻辑
            switch (this.invoicingType) {
                case 1: // 销售单
                    this.handleAmountValidation(this.dataSource, 1, text, val) &&
                        (this.invoiceNumber1 = this.getInvoiceNumber(this.dataSource));
                    break;

                case 4: // 货堆
                    this.handleAmountValidation(this.pileDataSource, 4, text, val) &&
                        (this.invoiceNumber4 = this.getInvoiceNumber(this.pileDataSource));
                    break;

                case 5: // 宅配
                    this.handleAmountValidation(this.resDataSource, 5, text, val) &&
                        (this.invoiceNumber5 = this.getInvoiceNumber(this.resDataSource));
                    break;

                case 6: // 线下
                    this.handleAmountValidation(this.offDataSource, 6, text, val) &&
                        (this.invoiceNumber6 = this.getInvoiceNumber(this.offDataSource));
                    break;

                default:
                    console.warn('未知的开票类型:', this.invoicingType);
            }
            // // 销售单
            // if (this.invoicingType == 1) {
            //     let index = this.dataSource.findIndex(i => i.id == text.id)
            //     if (val > text.surplusAmount) {
            //         this.$message.error('开票金额不能大于可开票金额,请重新输入')
            //         this.$set(this.dataSource[index], 'money', '');
            //     } else {
            //         this.$set(this.dataSource[index], 'money', val);
            //     }
            //     this.invoiceNumber1 = this.getInvoiceNumber(this.dataSource)
            // }
            // // 货堆
            // if (this.invoicingType == 4) {
            //     let index = this.pileDataSource.findIndex(i => i.code == text.code)
            //     if (val > text.surplusAmount) {
            //         this.$message.error('开票金额不能大于可开票金额,请重新输入')
            //         this.$set(this.pileDataSource[index], 'money', '');
            //     } else {
            //         this.$set(this.pileDataSource[index], 'money', val);
            //     }
            //     this.invoiceNumber4 = this.getInvoiceNumber(this.pileDataSource)
            // }
            // // 宅配
            // if (this.invoicingType == 5) {
            //     let index = this.resDataSource.findIndex(i => i.code == text.code)
            //     if (val > text.surplusAmount) {
            //         this.$message.error('开票金额不能大于可开票金额,请重新输入')
            //         this.$set(this.resDataSource[index], 'money', '');
            //     } else {
            //         this.$set(this.resDataSource[index], 'money', val);
            //     }
            //     this.invoiceNumber5 = this.getInvoiceNumber(this.resDataSource)
            // }
            // // 线下
            // if (this.invoicingType == 6) {
            //     let index = this.offDataSource.findIndex(i => i.code == text.code)
            //     console.log(typeof text.surplusAmount,'text.surplusAmount')
            //     console.log(typeof val,'val')
            //     if (val > text.surplusAmount) {
            //         this.$message.error('开票金额不能大于可开票金额,请重新输入')
            //         this.$set(this.offDataSource[index], 'money', '');
            //     } else {
            //         this.$set(this.offDataSource[index], 'money', val);
            //     }
            //     this.invoiceNumber6 = this.getInvoiceNumber(this.offDataSource)
            // }
        },
        // 统计角标值
        getInvoiceNumber(data) {
            const count = data.filter(item => item.money && item.money !== "").length;
            return count
        },
        // 保存
        save() {
            // 预开票
            if (this.activeTab == 2) {
                let that = this;
                if (!this.totalPrice || this.totalPrice <= 0) {
                    this.$message.warning('请输入开票金额！')
                    return;
                }
                let totalPrice = that.totalPrice
                let selectedList = [{ invoicingType: 3, money: totalPrice, }]
                that.$emit('getSelectCode', { selectedList, totalPrice })
                return false;
            }
            let selectedList = []
            let totalPrice = 0;

            // 循环处理 dataSource
            if (this.dataSource.length > 0) {
                this.dataSource.forEach(item => {
                    if (item.money) {
                        selectedList.push({ code: item.code, money: item.money, invoicingType: 1, surplusAmount: item.surplusAmountOld })
                        totalPrice += (item.money - 0)
                    }
                })
            }

            // 循环处理 pileDataSource
            if (this.pileDataSource.length > 0) {
                this.pileDataSource.forEach(item => {
                    if (item.money) {
                        selectedList.push({ code: item.code, money: item.money, invoicingType: 4, surplusAmount: item.surplusAmountOld })
                        totalPrice += (item.money - 0)
                    }
                })
            }

            // 循环处理 resDataSource
            if (this.resDataSource.length > 0) {
                this.resDataSource.forEach(item => {
                    if (item.money) {
                        selectedList.push({ code: item.code, money: item.money, invoicingType: 5, surplusAmount: item.surplusAmountOld })
                        totalPrice += (item.money - 0)
                    }
                })
            }

            // 循环处理 offDataSource
            if (this.offDataSource.length > 0) {
                this.offDataSource.forEach(item => {
                    if (item.money) {
                        selectedList.push({ code: item.code, money: item.money, invoicingType: 6, surplusAmount: item.surplusAmountOld })
                        totalPrice += (item.money - 0)
                    }
                })
            }

            // 判断是否大于预开票金额
            if (this.$route.query.status) {
                if (totalPrice != this.GlAmount) {
                    this.$message.error('开票金额必须等于预开票金额')
                    return;
                }
            }

            // 检查开票金额是否为有效值
            if (!totalPrice) {
                this.$message.warning('请输入开票金额！')
                return false;
            }

            // 四舍五入到两位小数
            totalPrice = Math.round(totalPrice * 100) / 100;

            // 格式化为两位小数字符串
            totalPrice = totalPrice.toFixed(2);

            // 触发事件
            this.$emit('getSelectCode', { selectedList, totalPrice });
        },
        findDuplicateIds(array) {
            const idMap = {};
            const duplicates = [];
            array.forEach((item, index) => {
                if (idMap[item.id] === undefined) {
                    idMap[item.id] = index;
                } else {
                    if (!duplicates.some(duplicate => duplicate.id === item.id)) {
                        duplicates.push({ id: item.id, indexes: [idMap[item.id], index] });
                    } else {
                        const existingDuplicate = duplicates.find(duplicate => duplicate.id === item.id);
                        existingDuplicate.indexes.push(index);
                    }
                }
            });
            return duplicates;
        },
        // 格式化年月日
        formatDate(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();
            let result = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`
            return result
        },
        formatDateMin(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();
            const hours = date.getHours();
            const minutes = date.getMinutes();
            const seconds = date.getSeconds();
            const result = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day} ${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
            return result;
        },
        init(data, record) {
            this.invoiceNumber1 = 0;
            this.invoiceNumber4 = 0;
            this.invoiceNumber5 = 0;
            this.invoiceNumber6 = 0;
            //已录入的开票信息
            this.selectData = data;
            // 编辑
            if (this.$route.query.id) {
                console.log(data, 'data[0].code')
                if (data[0].code) {
                    if (data[0].sfaInvoiceApplyDetailsParamList.length == 0) {
                        // 是预开票
                        this.isYKP = 2;
                        this.activeTab = 2
                    } else {
                        // 是预开票
                        this.isYKP = 1;
                        this.activeTab = 1
                    }
                } else {
                    // 是预开票
                    this.isYKP = 0;
                }
            } else {
                // 新增
                if (data[0].code) {
                    console.log(data[0].code, 'data[0].code')
                    if (data[0].code[0].invoicingType == 3) {
                        // 是否预开票
                        this.isYKP = 2;
                        this.activeTab = 2
                    } else {
                        this.isYKP = 1;
                        this.activeTab = 1
                    }
                } else {
                    this.isYKP = 0;
                }
            }

            if (this.$route.query.status) {
                // 预开票关联 最大金额
                this.GlAmount = record.money
                this.isYKP = 1;
                this.activeTab = 1
            }

            // 新增-预开票金额
            this.totalPrice = 0;
            this.getList();// 获取销售单数据
            this.getPileList();//获取货堆列表
            this.getResList();//获取宅配列表
            this.getOfflineList();//获取线下活动列表
        },
        // 销售单数据
        getList() {
            return new Promise((resolve) => {
                this.tabLoading = true;
                request(process.env.VUE_APP_API_BASE_URL + "sfa/saledocket/listbycustomerid", "post", this.queryData).then((res) => {
                    this.tabLoading = false;
                    let data = res.data;
                    this.dataSource = data.data.data;
                    let resultMap = new Map();
                    this.dataSource.forEach(item => {
                        item.surplusAmountOld = item.surplusAmount
                        if (resultMap.has(item.id)) {
                            let existingItem = resultMap.get(item.id);
                            // 检查属性是否相同
                            let same = true;
                            Object.keys(item).forEach(key => {
                                if (existingItem[key] !== item[key]) {
                                    same = false;
                                    // 如果属性值不同，则拼接到一个属性中
                                    existingItem[key] = existingItem[key] + ', ' + item[key];
                                }
                            });
                            // 如果所有属性都相同，只保留一个对象
                            if (same) {
                                resultMap.set(item.id, existingItem);
                            }
                        } else {
                            resultMap.set(item.id, item);
                        }
                    });
                    // 将 resultMap 转换为数组
                    let mergedArray = Array.from(resultMap.values());
                    this.dataSource = mergedArray;
                    if (this.selectData.length > 0) {
                        mergedArray.forEach(item => {
                            this.selectData.forEach(it => {
                                if (it.code) {
                                    it.code.forEach(son => {
                                        if (son.code == item.code) {
                                            item.surplusAmount = item.surplusAmount - son.money
                                            item.surplusAmount = item.surplusAmount.toFixed(2)
                                        }
                                    })
                                }
                            })
                        })
                    }

                    this.queryData.page = data.data.page;
                    this.queryData.size = data.data.size;
                    this.total = data.data.total;
                    resolve(); // 表示 getList() 方法执行完毕
                });
            });
        },
        getDetails() {
            if (this.$route.query.id) {
                request(process.env.VUE_APP_API_BASE_URL + 'sfa/invoiceapply/' + this.$route.query.id, 'get').then(res => {
                    this.form = res.data.data;
                    this.EditSfaInvoiceApplyDetailsList = this.form.sfaInvoiceApplyDetailsList
                    this.queryData.customerId = this.form.customerId;
                    this.queryData.invoiceId = this.form.invoiceId;
                    // 开票明细
                    this.sfaInvoiceApplyDetailsContentList = this.form.sfaInvoiceApplyDetailsContentList;
                    // 明细开票金额
                    this.sfaInvoiceApplyDetailsContentList.forEach(item => {
                        this.totalActualAmount = this.totalActualAmount + item.money
                    })
                    // 获取该客户发票列表
                    this.editList();
                    this.editPileData()
                    this.editResData()
                    this.editOffData()
                    // 关联
                    if (this.$route.query.status) {
                        this.invoicingType = 1;
                        this.glAmout = this.form.actualAmount
                        this.getList();
                        setTimeout(() => {
                            this.selectInvoiceChange(this.form.invoiceId);
                        }, 1500)
                    }
                    // 编辑
                    if (!this.$route.query.status) {
                        //  预开票
                        if (this.form.type == 2) {
                            this.glAmout = this.form.actualAmount
                        }
                    }
                });
            }
        },
        handlePageChange(page) {
            this.queryData.page = page - 1;
            this.currentPage = page;
            this.getList()
        },
        handlePageChange1(page) {
            this.pileParams.page = page - 1;
            this.pileParams.currentPage = page;
            this.getPileList()
        },
        handlePageChange2(page) {
            this.resParams.page = page - 1;
            this.resParams.currentPage = page;
            this.getResList()
        },
        handlePageChange3(page) {
            this.offParams.page = page - 1;
            this.offParams.currentPage = page;
            this.getOfflineList()
        },
        clearData() {
            this.selectRowsObj = {};
            this.selectedRowsList = [];
            this.selectedRowsList1 = [];
            this.selectedRowsList2 = [];
            this.selectedRowsList3 = [];
            this.selectedRowsList4 = [];
            this.selectedRowsKeys1 = [];
            this.selectedRowsKeys2 = [];
            this.selectedRowsKeys3 = [];
            this.selectedRowsKeys4 = [];
            this.form.sfaInvoiceApplyDetailsList = [];
            this.form.actualAmount = 0;
            this.invoiceAmount = 0;
        },
        selectInvoiceChange(id) {
            this.invoiceList.forEach(item => {
                if (id == item.id) {
                    this.selectInvoice = item;
                }
            })
        },
        // 切换tab
        callback(key) {
            this.glAmout = '';
            this.invoicingType = key;

        },
        // 选中销售单
        onSelectChange1(selectedRowKeys, selectedRows) {
            console.log(`selectedRowKeys1: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            this.selectedRowsKeys1 = selectedRowKeys;
            this.selectedRowsList1 = selectedRows;
            this.selectRowsObj1 = selectedRows[0];
            this.salcActualAmount()
        },
        onSelectChange2(selectedRowKeys, selectedRows) {
            console.log(`selectedRowKeys2: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            this.selectedRowsKeys2 = selectedRowKeys;
            this.selectedRowsList2 = selectedRows;
            this.selectRowsObj2 = selectedRows[0];
            this.salcActualAmount()
        },
        onSelectChange3(selectedRowKeys, selectedRows) {
            console.log(`selectedRowKeys3: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            this.selectedRowsKeys3 = selectedRowKeys;
            this.selectedRowsList3 = selectedRows;
            this.selectRowsObj3 = selectedRows[0];
            this.salcActualAmount()
        },
        onSelectChange4(selectedRowKeys, selectedRows) {
            this.selectedRowsKeys4 = selectedRowKeys;
            this.selectedRowsList4 = selectedRows;
            this.selectRowsObj4 = selectedRows[0];
            this.salcActualAmount()
        },
        getPileList() {
            this.pileParams.customerId = this.queryData.customerId
            return new Promise((resolve) => {
                if (this.queryData.customerId) {
                    this.pileLoading = true;
                    request(process.env.VUE_APP_API_BASE_URL + "sfa/saledocket/queryPageBycustomerid", "post", this.pileParams).then((res) => {
                        this.pileLoading = false;
                        let data = res.data;
                        this.pileDataSource = data.data.data;
                        this.pileParams.page = data.data.page;
                        this.pileParams.size = data.data.size;
                        this.pileTotal = data.data.total;
                        if (this.selectData.length > 0) {
                            this.pileDataSource.forEach(item => {
                                item.surplusAmountOld = item.surplusAmount
                                this.selectData.forEach(it => {
                                    if (it.code) {
                                        it.code.forEach(son => {
                                            if (son.code == item.code) {
                                                item.surplusAmount = item.surplusAmount - son.money
                                                item.surplusAmount = item.surplusAmount.toFixed(2)
                                            }
                                        })
                                    }
                                })
                            })
                        }
                        resolve(); // 表示 getList() 方法执行完毕
                    });
                }
            });
        },
        // 获取宅配列表
        getResList() {
            this.resParams.customerId = this.queryData.customerId
            return new Promise((resolve) => {
                if (this.queryData.customerId) {
                    this.resLoading = true;
                    request(process.env.VUE_APP_API_BASE_URL + "sfa/saledocket/queryPageBycustomerid", "post", this.resParams).then((res) => {
                        this.resLoading = false;
                        let data = res.data;
                        this.resDataSource = data.data.data;
                        this.resParams.page = data.data.page;
                        this.resParams.size = data.data.size;
                        this.resTotal = data.data.total;
                        if (this.selectData.length > 0) {
                            this.resDataSource.forEach(item => {
                                item.surplusAmountOld = item.surplusAmount
                                this.selectData.forEach(it => {
                                    if (it.code) {
                                        it.code.forEach(son => {
                                            if (son.code == item.code) {
                                                item.surplusAmount = item.surplusAmount - son.money
                                                item.surplusAmount = item.surplusAmount.toFixed(2)
                                            }
                                        })
                                    }
                                })
                            })
                        }
                        resolve(); // 表示 getList() 方法执行完毕
                    });
                }
            });
        },
        // 获取线下活动列表
        getOfflineList() {
            this.offParams.customerId = this.queryData.customerId
            return new Promise((resolve) => {
                if (this.queryData.customerId) {
                    this.offLoading = true;
                    request(process.env.VUE_APP_API_BASE_URL + "sfa/offlineActivity/invoiceList", "post", this.offParams).then((res) => {
                        this.offLoading = false;
                        let data = res.data;
                        this.offDataSource = data.data.data;
                        this.offParams.page = data.data.page;
                        this.offParams.size = data.data.size;
                        this.offTotal = data.data.total;
                        if (this.selectData.length > 0) {
                            this.offDataSource.forEach(item => {
                                item.surplusAmountOld = item.surplusAmount
                                this.selectData.forEach(it => {
                                    if (it.code) {
                                        it.code.forEach(son => {
                                            if (son.code == item.code) {
                                                item.surplusAmount = item.surplusAmount - son.money
                                                item.surplusAmount = item.surplusAmount.toFixed(2); // 保留两位小数并转为字符串
                                                console.log(item.surplusAmount, 'item.surplusAmount')
                                            }
                                        })
                                    }
                                })
                            })
                        }
                        resolve(); // 表示 getList() 方法执行完毕
                    });
                }
            });
        },
        // 开票详情数组
        sendCententList(data) {
            this.form.sfaInvoiceApplyDetailsContentList = data;
            this.totalActualAmount = 0;
            this.sfaInvoiceApplyDetailsContentList = data;
            data.forEach(item => {
                this.totalActualAmount = this.totalActualAmount + (item.money - 0)
                this.$set(this, 'totalActualAmount', this.totalActualAmount);
            })
        },
        // form.actualAmount
        // 合并选中列表 计算可开票合计金额
        salcActualAmount() {
            this.invoiceAmount = 0;
            this.selectedRowsList = [];
            this.form.sfaInvoiceApplyDetailsList = [];
            if (this.selectedRowsList1.length > 0) {
                this.selectedRowsList1.forEach(item => {
                    this.invoiceAmount = (this.invoiceAmount - 0) + (item.surplusAmount - 0);
                    item.invoicingType = 1;//销售单
                    this.selectedRowsList.push(item);
                    this.form.sfaInvoiceApplyDetailsList.push({
                        invoicingType: item.invoicingType,
                        detailCode: item.code
                    });
                })
            }
            if (this.selectedRowsList2.length > 0) {
                this.selectedRowsList2.forEach(item => {
                    this.invoiceAmount = (this.invoiceAmount - 0) + (item.surplusAmount - 0);
                    item.invoicingType = 4;//货堆
                    this.selectedRowsList.push(item);
                    this.form.sfaInvoiceApplyDetailsList.push({
                        invoicingType: item.invoicingType,
                        detailCode: item.code
                    });
                })
            }
            if (this.selectedRowsList3.length > 0) {
                this.selectedRowsList3.forEach(item => {
                    this.invoiceAmount = (this.invoiceAmount - 0) + (item.surplusAmount - 0);
                    item.invoicingType = 5;//宅配
                    this.selectedRowsList.push(item);
                    this.form.sfaInvoiceApplyDetailsList.push({
                        invoicingType: item.invoicingType,
                        detailCode: item.code
                    });
                })
            }
            console.log(this.selectedRowsList4, 'this.selectedRowsList4')
            if (this.selectedRowsList4.length > 0) {
                this.selectedRowsList4.forEach(item => {
                    this.invoiceAmount = (this.invoiceAmount - 0) + (item.surplusAmount - 0);
                    item.invoicingType = 6;//线下活动
                    this.selectedRowsList.push(item);
                    this.form.sfaInvoiceApplyDetailsList.push({
                        invoicingType: item.invoicingType,
                        detailCode: item.code
                    });
                })
            }
        },
    }
}
</script>
<style>
.tips-rad {
    color: #fff;
    min-width: 14px;
    height: 14px;
    text-align: center;
    line-height: 14px;
    display: inline-block;
    font-size: 12px;
    background: red;
    margin-right: 4px;
    border-radius: 100%;
}


::v-deep .ant-form-item-control {
    line-height: 10px !important;
}


.page-box {
    margin-left: auto;
    padding-right: 46px;
    margin-top: 10px;
}
</style>